(function ($) {
    $(window).ready(function () {
        $('[data-select-filter]').each(function () {
            var $selectFilter = $(this);
            var selects = $selectFilter.find('[data-select-filter-item]');
            var $itemsContainer = $selectFilter.find('[data-select-filter-items]');
            var $resetButton = $selectFilter.find('[data-reset-select-filter]');
            selects.on('change', function () {
                var filters = [];
                var url = new URL(window.location);
                selects.each(function () {
                    var cssClass = $(this).val();
                    var slug = $(this).attr('data-select-filter-item');
                    if (cssClass) {
                        filters.push(cssClass);
                        url.searchParams.set(slug, cssClass.replaceAll('.' + slug + '-', ''));
                    } else {
                        url.searchParams.delete(slug);
                    }
                });
                window.history.pushState({}, '', url);

                if (filters.length > 0) {
                    $resetButton.addClass('available');
                    $itemsContainer.children(filters.join('')).show();
                    $itemsContainer.children().not(filters.join('')).hide();
                } else {
                    $resetButton.removeClass('available');
                    $itemsContainer.children().show();
                }
            });
            selects.change();
            $resetButton.on('click', function () {
                selects.val('').change();
            });
        });
        $('[data-bubble-filter]').each(function () {
            var $filter = $(this);
            var $bubbles = $filter.find('[data-bubble]');
            var $items = $filter.find('[data-bubble-item]');
            $bubbles.on('click', function () {
                console.log('click');
                $clickedBubble = $(this);
                if (!$clickedBubble.hasClass('active')) {
                    $bubbles.removeClass('active');
                    $clickedBubble.addClass('active');
                    var cssClass = $clickedBubble.attr('data-bubble');
                    if (cssClass) {
                        $items.not(cssClass).hide();
                        $items.filter(cssClass).show();
                    } else {
                        $items.show();
                    }
                }
            });
        });
    });

    $('[data-ct-print]').submit(function(e){
        var url = new URL(window.location);
        $(this).find('[name="filter"]').val(JSON.stringify(Object.fromEntries(url.searchParams.entries())));
    });
})(jQuery);