(function ($) {
    $(document).ready(function () {
        $('[data-countdown]').each(function () {
            var $countdown = $(this);
            var $positions =  $countdown.find('[data-counter-position]');
            var date = new Date($countdown.attr('data-countdown'));
            var timer = setInterval(function () {
                var now = new Date();
                if (date.getTime() > now.getTime()) {
                    var time = date.getTime() - now.getTime();
                    $positions.each(function () {
                        var seconds = parseInt($(this).attr('data-counter-position'));
                        var positionTime = (Math.floor(time / seconds)).toString();
                        $(this).text((positionTime.length !== 1 ? positionTime : '0' + positionTime));
                        time %= seconds;
                    });
                } else {
                    $positions.each(function () {
                        $(this).text('00');
                    });
                    clearInterval(timer);
                }
            }, 1000);
        });
    });
})(jQuery);
