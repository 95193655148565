/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
		  // JavaScript to be fired on all pages

		// init selectpicker for other fields
		jQuery('.address_country > select, .gfield > div > select').selectpicker();

		// Select filter dropdown
        $('.select-filter').find('.select-arrow').click(function() {

            var bSel = $(this).parents('.select-filter:first').find('.bootstrap-select');

            if (bSel.hasClass("open")) {
                bSel.removeClass("open");
            } else {
                bSel.addClass("open");
            }

            return false;
        });

		//slider
		$(document).ready(function() {
			$('.header-slider').slick({
				autoplay: true,
				autoplaySpeed: 2000,
				dots: false,
				fade: false,
				arrows: false,
				slidesToShow: 8,
				slidesToScroll: 1,
				responsive: [{
					breakpoint: 1200,
					settings: {
						slidesToShow: 4,
					}
				}, {

					breakpoint: 600,
					settings: {
						slidesToShow: 3,
					}
				}]
			});

			// Start-Page: Display all "Medaillenticker" and "Letzte Resutate" on same height
			var maxHeight = 0;
			var elResultsRows = $(".card-last-results-row");
			if (elResultsRows.length) {
				elResultsRows.each(function(index, elResultsRow) {
					maxHeight = Math.max(maxHeight, $(elResultsRow).height());
				});
				elResultsRows.css("height", maxHeight + "px");
			}
		});

		// GravityForm & Payrexx
		$(document).ready(function() {
			var donateForm = $(".gform_wrapper .spendenformular");
			if (donateForm.length) {
				$("input[type=submit]", donateForm).click(function (event) {
					if ($(".ct-id-donation-payment input[type=radio]:checked", donateForm).val() === "card") {
						var amount = $(".ct-id-donation-amount input", donateForm).val();
						if (amount === 0 || amount === "") {
							// Read the value from the "Spendenart"
							amount = $(".ct-id-donation-purpose input[type=radio]:checked", donateForm).val();
						}
						// Text for field "purpose" has to be token in the next element (label) of the input one
						var purposeInput = $(".ct-id-donation-purpose input[type=radio]:checked", donateForm);
						var purpose = $("label", purposeInput.parent()).html();

						// Save the needed data for Payrexx
						if ($(".payrexx-modal-window").length === 0) {
							$(".content_page").prepend("<a class='payrexx-modal-window' href='#'></a>");
						}

						// According to "Virtuelles Terminal" described in Payrexx
						var dataHref = "https://swissparalympic.payrexx.com";
						dataHref += '/'+ language; // polylang language slug
						dataHref += "/vpos?currency=CHF";
						dataHref += "&amount=" + amount;
						dataHref += "&purpose=" + purpose;
						if ($(".ct-id-donation-firstname input", donateForm).length) {
							dataHref += "&contact_forename=" +  $(".ct-id-donation-firstname input", donateForm).val();
						}
						if ($(".ct-id-donation-lastname input", donateForm).length) {
							dataHref += "&contact_surname=" + $(".ct-id-donation-lastname input", donateForm).val();
						}
						if ($(".ct-id-donation-email input", donateForm).length) {
							dataHref += "&contact_email=" + $(".ct-id-donation-email input", donateForm).val();
						}
						$(".payrexx-modal-window").attr("data-href", dataHref);
					}
					else {
						// To be sure to don't have any ghost element ...
						$(".payrexx-modal-window").remove();
					}
				});
			}
		});
		$(window).on('gform_confirmation_loaded', function(event, formId){
			// code to be trigger when confirmation page is loaded
			if ($(".payrexx-modal-window").length) {
				$(".payrexx-modal-window").payrexxModal({
					shown: function() {
						var pxIcon = $(".ct-payrexx-icon");
						if (pxIcon.length === 0) {
							var pxIframe = $("#payrexx-modal-iframe");
							if (pxIframe.length) {
								var pxContainer = pxIframe.parent();
								pxContainer.prepend("<div class='ct-payrexx-icon'><button onclick='document.location.reload(true)' class='btn fa fa-close'></button></div>");
							}
						}
					}
				});
				$(".payrexx-modal-window").trigger("click");
			}
		});

		$(document).ready(function() {
			// Move the "catchphrase" just below the image (useful at least for mobile)
			// ... but only after a little timeout, else a wrong value is token in account
			setTimeout(function() {
				$(window).trigger("resize");
			}, 10);
		});



		// expand and colaps header
		$(window).on('scroll touchmove', function() {
      		if ($(window).scrollTop() > 100 ) {
				$('[data-size="big"]').addClass('header-collapsed').attr('data-size', 'small');
			} else {
				$('[data-size="small"]').removeClass('header-collapsed').attr('data-size', 'big');
			}
    	});

       // Close the navbar
  		var closeNavbar = function() {
	  		$('.icon-bar').show();
				$('.navbar-toggle').removeClass('open');
				$('body').removeClass('mobile-navigation-open');

	  		$('html, body').css({
		  		overflow: "auto",
		  		height: "auto"
	  		});

	  		$('.meta-navigation').removeClass("navbar-open");
	  		$('.main-navigation.collapse').removeClass('open');
  		};

  		// Open the navbar
  		var openNavbar = function() {
				$('.navbar-toggle').addClass('open');
				$('body').addClass('mobile-navigation-open');

	  		$('html, body').css({
		  		overflow: "hidden",
		  		height: "100%"
	  		});

	  		$('.meta-navigation').addClass("navbar-open");
	  		$('.main-navigation.collapse').addClass("open");
  		};

  		// Nav bar toggle
  		$('.navbar-toggle').click(function(event) {
        event.preventDefault();
	  		if ($('.main-navigation.collapse').hasClass('open')) {
		  		closeNavbar();
	  		} else {
		  		openNavbar();

		  		// Close the navbar by clicking outside the navbar
		  		$('body').click(function(e) {
			  		if (! $(e.target).hasClass('main-navigation') && ! $(e.target).parents().hasClass('main-navigation') && $('.main-navigation.collapse').css('left') === '0px') {
				  		closeNavbar();
			  		}
		  		});
	  		}
});


	  },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

	$(function() {
		$('a[href*="#"]:not([href="#"]):not([role="tab"])').click(function() {
			if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
				var target = $(this.hash);
				target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
				if (target.hasClass('tab-pane')) {
					return;
				}
				if (target.length) {
					$('html, body').animate({
				        scrollTop: target.offset().top - 180
				    }, 2000);
					return false;
				}
			}
		});

		$('a[href*="#"][role="tab"]').click(function() {
			location.hash = $(this).attr('href');
			var url = new URL(window.location);
			url.searchParams.forEach(function(value, key) {
				url.searchParams.delete(key);
			});
			window.history.pushState({}, '', url);
		});

		// *only* if we have anchor on the url
	    if(window.location.hash) {
			if ($(window.location.hash).hasClass('tab-pane')) {
				$('a[href="' + window.location.hash + '"]').trigger('click');
			}
	    }

        $('.table-accordion-toggler').on('click', function () {
            $(this).parents('.table-accordion-row').toggleClass('active');
        });

	});



  // Load Events
  $(document).ready(UTIL.loadEvents);

  function setCookie(key, value, expiry) {
    var expires = new Date();
    expires.setTime(expires.getTime() + (expiry * 24 * 60 * 60 * 1000));
    document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
  }

  function getCookie(key) {
    var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : null;
  }

  // Infomodal
  $(document).ready(function() {
    if ( $( "#infoModal" ).length ) {
      $("#infoModal").click(function (el) {
        if ($(el.target).hasClass('modal')) {
          $(el.target).find('.close').click();
        }
      });

      $("#infoModal .btn").click(function (el) {
        $(el.target).closest('#infoModal').find('.close').click();
      });

      $('#infoModal').on('hidden.bs.modal', function (e) {
        setCookie('infoModal', 'clicked', '1');
      });

      var isCookie = getCookie('infoModal');
      if (isCookie !== 'clicked') {
        setTimeout(function () {
          $('#infoModal').modal({
            backdrop: false
          });
        }, 500);
      }
    }
  });

})(jQuery); // Fully reference jQuery after this point.
