jQuery(document).ready(function() {

    function wrapTable() {
        jQuery( 'div.content_page table' ).wrap( '<div class="table-wrapper">' );
    }

    // Wrap table content in container on mobile
    if( jQuery( '.table-wrapper' ).length === 0 && jQuery(window).width() <= 768 ) {
        wrapTable();
    }

    jQuery(window).on('resize', function(){
        if( jQuery( '.table-wrapper' ).length === 0 && jQuery(window).width() <= 768 ) {
            wrapTable();
        }
    });
});