var masonryNewsCard = null;

var indexInternalNews = 0;
var indexJuicerNews   = 0;

function replaceAnonymousInstagramTitle($element) {
    if($element === undefined){
        $element = jQuery('[data-feed-id]');
    }
    // Replace Instagram-Title "An Instagram User" by Hashtag ...
    var titleToReplace = "An Instagram User";
    var hashtagToDisplay = $element.attr("data-feed-id");
    if (hashtagToDisplay.length > 0) {
        hashtagToDisplay = "#" + hashtagToDisplay;
        var $elementLiSource = jQuery("li[data-source]", $element);
        $elementLiSource.each(function() {
            $li = jQuery(this);
            if ($li.attr("data-source") === "instagram") {
                var $title = jQuery(".j-poster h3", $li);
                if ($title.length && $title.html() === titleToReplace) {
                    $title.html(hashtagToDisplay);
                    // Try to update as well the title in the overlay when click
                    $element.on("click", function(event) {
                        var $title = jQuery(".j-overlay .j-poster h3");
                        if ($title.length && $title.html() === titleToReplace) {
                            $title.html(hashtagToDisplay);
                        }
                    });
                }
            }
        });
    }
}

function updateJuicerFeedWithSorting(elementId) {
    // Remove first the "LOAD MORE" button
    var element = jQuery("#" + elementId);
    element.find('.j-paginate').remove();

    // Put this element at the right place (ordered by date)
    // - Define the time of this feed
    var valDateTime = element.find(".j-date[datetime]").attr("datetime");
    var theDate = (new Date(valDateTime)).getTime() / 1000;

    var placeFound = false;
    jQuery(".news-cards").children().each(function() {
        var valDate = jQuery(this).attr("data-post-date");
        if (valDate < theDate) {
            // The element has to be introduced before this one
            element.attr("data-post-date", theDate);
            element.insertBefore(jQuery(this));
            placeFound = true;
            return false;
        }
    });
    if (!placeFound) {
        // Put it to the last position (keeping "read-more" at the end)
        element.attr("data-post-date", theDate);
        jQuery(element).insertBefore(jQuery(".news-cards-read-more"));
    }

    if (jQuery(".news-cards-juicer").children().length === 0) {
        // Remove the old Juicer container, which should be now empty
        // That can disturb the "masonry", for example without internal news
        jQuery(".news-cards-juicer").remove();
    }

    // Replace Instagram-Title "An Instagram User" by Hashtag ...
    replaceAnonymousInstagramTitle(element);

    if (jQuery(".news-card-masonry").length) {
        // Update the new masonry container
        masonryNewsCard = new Masonry( jQuery(".news-card-masonry")[0], {
            itemSelector: ".card",
            columnWidth: ".grid-sizer",
            percentPosition: true
        });
        if (masonryNewsCard) {
            masonryNewsCard.layout();
            // There still seems to have problem: This event 'updateJuicerFeedWithSorting'
            // comes while render doesn't seem really ready ...
            // No other cleaner solution found !
            setTimeout(function() {
                masonryNewsCard.layout();
            }, 500);
            setTimeout(function() {
                masonryNewsCard.layout();
            }, 1300);
            setTimeout(function() {
                masonryNewsCard.layout();
            }, 3000);

            // Apply the filter
            jQuery(".news-categories-active").trigger("click");
        }
    }
}

function updateJuicerFeedSimple(elementId) {
    // Remove first the "LOAD MORE" button
    jQuery(".j-paginate").remove();

    // Replace Instagram-Title "An Instagram User" by Hashtag ...
    replaceAnonymousInstagramTitle( jQuery("#" + elementId) );
}

function juicerFeedLoaded(){
    if(jQuery('.news-cards-juicer').height() < jQuery('.news-cards-post').height()){
        jQuery('.juicer-button').trigger('click');
    }
}

function readMoreNewsInternalWP(nbrNews) {
    if (indexInternalNews === 0) {
        // The very first time, as News are directly displayed, the index-counter is not up-to-date !
        indexInternalNews += nbrNews;
    }
    if (indexInternalNews === -1) {
        // All news have already been read
        return;
    }
    jQuery.ajax({
        type   : "POST",
        url    : projectsAjax.url,
        data   : {
            action : "morenews",
            params : { nbrNews : nbrNews, offsetNews : indexInternalNews }
        },
        success: function(response) {
            if (response.success && response.data) {
                jQuery('.news-cards-post').append(response.data);
                indexInternalNews += nbrNews;
                jQuery(".news-categories-active").trigger("click");
            }
            else {
                // No news available anymore
                indexInternalNews = -1;
            }
        },
        error: function(param) {
        }
    });
}

function readMoreNews(nbrNews) {
    readMoreNewsInternalWP(nbrNews);
    jQuery('.juicer-button').trigger('click');
}



(function($) {
    $(document).ready(function() {
        $('[data-filter]').on('click', function(event) {
            event.preventDefault();

            $(".news-categories-active").removeClass('news-categories-active');
            $(this).addClass('news-categories-active');
            if ($(this).attr("data-filter") === "all") {
                $('[data-news]').children().show(300).removeClass('col-md-12').addClass('col-md-6').children().not('.juicer-feed').removeClass('col-md-4');
            }
            else {
                $('[data-news]').children().filter($(this).attr('data-filter')).show(300).addClass('col-md-12').removeClass('col-md-6').children().not('.juicer-feed').addClass('col-md-4');
                $('[data-news]').children().not($(this).attr('data-filter')).hide(300).removeClass('col-md-12').addClass('col-md-6').children().not('.juicer-feed').removeClass('col-md-4');
            }
        });
    });
})(jQuery);
